import React from 'react';
import { Helmet } from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';

export const Meta = ({ title, description, pathname }) => {
  const {
    site: { siteMetadata },
  } = useStaticQuery(
    graphql`
      query SiteMetaData {
        site {
          siteMetadata {
            title
            description
            url
            image
          }
        }
      }
    `
  );

  const meta = {
    title: title || siteMetadata.title,
    description: description || siteMetadata.description,
    image: `${siteMetadata.url}/${siteMetadata.image}`,
    type: 'website',
    url: pathname ? `${siteMetadata.url}/${pathname}` : siteMetadata.url,
  };

  return (
    <Helmet
      title={title}
      defaultTitle={`${siteMetadata.title}, ${siteMetadata.description}`}
      titleTemplate={`%s | ${siteMetadata.title}, ${siteMetadata.description}`}
    >
      <html lang="sk" />

      {/* Primary Meta Tags */}
      {/* <meta name="google-site-verification" content="..." /> */}
      <meta name="description" content={meta.description} />

      {/* Open Graph / Facebook */}
      <meta property="og:type" content={meta.type} />
      <meta property="og:title" content={meta.title} />
      <meta property="og:description" content={meta.description} />
      <meta property="og:image" content={meta.image} />
      <meta property="og:url" content={meta.url} />

      {/* Twitter */}
      <meta property="twitter:card" content="summary_large_image" />
      <meta property="twitter:title" content={meta.title} />
      <meta property="twitter:description" content={meta.description} />
      <meta property="twitter:image" content={meta.image} />

      <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
      <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
      <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
    </Helmet>
  );
};
