import React from 'react';
import { Link } from 'gatsby';
import { Container } from './Container';
import { Navigation } from './Navigation';

import logo from '../images/logo.png';

export const Layout = ({ children, separator = true }) => (
  <>
    <header role="banner" className={`header${separator ? ' header--separator' : ''}`}>
      <Container className="flex flex-col items-center lg:flex-row lg:justify-between">
        <Link to="/" className="py-6 md:py-10 max-w-xxs">
          <img src={logo} alt="logo Finlux" />
        </Link>
        <Navigation />
      </Container>
    </header>

    {children}

    <footer
      role="contentinfo"
      className="text-center sm:text-left text-sm py-8 text-blue relative overflow-hidden shadow-outer"
    >
      <Container className="sm:flex">
        <div className="flex-1 mb-4 sm:mb-0">
          © {new Date().getFullYear()} Finlux kuchynské štúdio Košice | <Link to="/kontakt">Kontakt</Link>
        </div>
        <a href="https://romanvesely.com" title="Vyrobil Roman Veselý" className="text-xs sm:text-base">
          &lt;rmnvsl /&gt;
        </a>
      </Container>
    </footer>
  </>
);
