import React from 'react';
import { Link } from 'gatsby';

const NavItem = ({ children, to, partiallyActive = false }) => (
  <li>
    <Link
      to={to}
      className="text-brown transition duration-300 ease hover:text-blue no-underline hover:bg-white flex items-center py-4 px-1 sm:px-2 lg:py-0 lowercase"
      activeClassName="nav-link--active"
      partiallyActive={partiallyActive}
    >
      {children}
    </Link>
  </li>
);

export const Navigation = () => (
  <nav role="navigation">
    <ul className="flex text-lg sm:text-2xl justify-center md:justify-start">
      <NavItem to="/">Úvod</NavItem>
      <NavItem to="/o-studiu">O&nbsp;štúdiu</NavItem>
      <NavItem to="/kuchyne-nabytok-na-mieru">Výroba</NavItem>
      <NavItem to="/servis-oprava-kuchynskych-liniek">
        Servis
        <span className="hidden sm:inline">
          &nbsp;<span style={{ fontSize: '0.75em' }}>a</span>&nbsp;oprava
        </span>
      </NavItem>
      <NavItem to="/kontakt">Kontakt</NavItem>
    </ul>
  </nav>
);
