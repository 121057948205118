import React from 'react';
import { Container } from './Container';

export const Main = ({ children }) => (
  <main
    role="main"
    className="bg-brown-light border-t-4 border-blue-light pt-6 pb-4 md:py-10 relative overflow-hidden shadow-outer"
  >
    <Container>{children}</Container>
  </main>
);
